// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import { Segment, Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import '../components/style.css'

export const HomePage = observer(() => (
  <div>
    <div style={{ backgroundImage: "url('/custom-content/vector-art-bg.webp')" }} className='landing-bg'>
      <div className='home-container'>
        <h1 style={{
          color: '#e1e6e7',
          margin: '6px 0',
          fontSize: '4rem'
        }}>
          {fragments.Home.header}
        </h1>
        {/* <p style={{
          color: '#313638',
          fontSize: '1.5rem',
          border: '1px solid #e1e6e7',
          backgroundColor: '#e1e6e7'
        }}>
          {fragments.Home.tagline}
        </p> */}
        <Link data-testid='apiListLink' to='/dashboard' className='our-api-btn' >
          {fragments.Home.apiListButton}
        </Link>
      </div>
    </div>
    <Segment vertical style={{ padding: '40px 0px', margin: '0 !important' }} className="landing-page-txt">
      <Container fluid text textAlign='justified'>
        <fragments.Home.jsx />
      </Container>
    </Segment>
  </div>
))

export default HomePage
