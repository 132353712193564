// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/* eslint-disable */
import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout,
  getLoginRedirectUrl
} from 'services/self'

import { cognitoDomain, cognitoClientId } from '../services/api'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import { store } from 'services/state'

import "./style.css"

function getCognitoUrl (type) {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/oauth2/authorize?client_id=${cognitoClientId}&response_type=token&scope=openid&redirect_uri=${redirectUri}`
}

export const NavBar = observer(
  class NavBar extends React.Component {
    render () {
      const email = store.user && store.user.email
      return (
        <div className='nav-bar-container'>
          <div className='nav-bar-icon'>
            <Link to='/'>
              <div className="nav-bar-logo">
                  <img src="/custom-content/home-image.png"/>
              </div>
            </Link>
          </div>
          <div className='nav-bar-left'>
            <ul className="nav">
              {/* <li><Menu.Item as={Link} to="/getting-started" name={fragments.GettingStarted.title} /></li> */}
              <li><Menu.Item as={Link} to="/dashboard" name={"Dashboard"} /></li>
            </ul>
          </div>
          <div className='nav-bar-right'>
            <ul className="nav">
              {isAuthenticated() ? 
                <>
                  {isAdmin() && <li><Menu.Item as={Link} to='/admin' name='Admin Panel' /></li>}
                  <li>
                    <Menu.Item as='a' onClick={logout} href="#" name='Sign Out' />
                  </li>
                </> 
                : 
                <>
                  <li>
                    <Menu.Item as='a' href={getCognitoUrl('login')} name='Sign In' />
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
      )
    }
  }
)

export default NavBar
