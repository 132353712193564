/* eslint-disable */
import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import {AddAccountContent, DeleteAccountContent, DocumentationContent, CreateFolderContent, FolderViewContent} from 'components/AccountsManagement/ModalContents'
import ModalComponent from 'components/AccountsManagement/ModalComponent'
import SearchBoxComponent from 'components/SearchBox/SearchBoxComponent'
import MessageBox from 'components/MessageBox'

// css
import '../components/AccountsManagement/accountManagement.css'

// sematic-ui
import {Container, Grid, Accordion, Icon, Checkbox, Button} from 'semantic-ui-react'

// client credentials
import { apiGatewayClientWithCredentials } from 'services/api'
import { store } from 'services/state'
import { observer } from 'mobx-react'


function AccountsManagement (props) {
    // accordian
    const [activeIndex, setActiveIndex] = useState(-1)
    const [activeIndex2, setActiveIndex2] = useState(-1)
    const [accList, setAccList] = useState(null)
    const [folderList, setFolderList] = useState(null)
    const [accAPIList, setAccAPIList] = useState([])
    const [domainFilter, setDomainFilter] = useState({})
    const [accordianLoader, setAccordianLoader] = useState(false)
    const [disableCheckbox, setDisableCheckbox] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [searchQuery2, setSearchQuery2] = useState('')
    const [msgBoxContent, setMsgBoxContent] = useState('')
    const [reload, setReload] = useState(false)

    useEffect(()=>{
        api_callback({'type': 'getAccountList'}, '/accounts')
        api_callback({'type': 'get_all_folders'}, '/apidocs')
    }, [])

    const handleClick = async (e, props) => {
        const index = props.index
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
        setSearchQuery('')
        setAccAPIList([])

        if(index >= 0 && activeIndex != index){
            setAccAPIList([])
            setAccordianLoader(true)
            await apiGatewayClientWithCredentials().then(app => app.post('/accounts', {}, {'type':'getAccAPIList',...accList[index]}, {}))
                .then(
                    res => {
                        setAccAPIList(res.data['api_list'])
                        setDomainFilter(res.data['domain_category'])
                    }, 
                    err => {
                        console.log(err.data)
                        // setMsgBoxContent({text: err.data, color: 'red', icon: 'close', header: 'Warning'})
                    }
                )
            setAccordianLoader(false)
        }

    }

    const api_callback = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                if(path=='/accounts'){setAccList(res.data)}
                else if(path=='/apidocs'){setFolderList(res.data)}
            },
            err=>{
                console.log(err.data)
                // setMsgBoxContent({text: err.data, color: 'red', icon: 'close', header: 'Warning'})
            }
        )
    }

    const apiCheckboxHandler = async (e, data) => {
        setDisableCheckbox(true)
        document.body.style.pointerEvents='none'

        let dataset = {type: 'AddRemoveAPIs', enabled: data.checked, acc_id: data['data-acc_id'], 
                        api_id: data['data-api_id'], stage: data['data-stage']}

        await apiGatewayClientWithCredentials().then(app => app.post('/accounts', {}, dataset, {}))
        .then(
            res => {
                var dummy = [...accAPIList]
                dummy.map(obj => {
                    if(obj.id == data['data-api_id']){
                        obj.stage.map(stage => {
                            if(stage.stage == data['data-stage']){
                                stage.enabled= !stage.enabled
                            }
                        })
                    }
                })
                console.log(res.data)
                // setMsgBoxContent({text: res.data, color: 'blue', icon: 'check', header: 'Status'})
            },
            err=>{
                console.log(err.data)
                // setMsgBoxContent({text: err.data, color: 'red', icon: 'close', header: 'Warning'})
            }
        )

        setDisableCheckbox(false)
        document.body.style.pointerEvents='auto'
    }

    if(store.user){
        return (
            <div className='containerbox'>
                {/* <MessageBox content={msgBoxContent} msgBoxState={setMsgBoxContent}/> */}
                <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                    <SearchBoxComponent txtClr='#777777' width='250px' bg='transparent' searchEvent={setSearchQuery2}/>
                </div>
                <Grid>
                    <Grid.Row>
                        <Container>
                            <ModalComponent size='tiny' content={AddAccountContent} type='addAccount' header='Add New Account' triggerName='Add Account' triggerIcon='add user'/>
                            <ModalComponent size='tiny' content={CreateFolderContent} type='addFolder' header='Add New Folder' triggerName='Add Folder' triggerIcon='folder'/>
                        </Container>
                    </Grid.Row>

                    <Grid.Row style={{paddingTop: '30px'}}>
                        <Grid doubling container columns={8}>
                            {folderList == null ?
                                [...Array(8)].map((obj, i)=>(
                                    <Grid.Column key={i}>
                                        <div style={{width: '80px', height: '80px', borderRadius:'4px'}} className='skeleton2'></div>
                                    </Grid.Column>
                                ))
                            :   
                                <>
                                    <h1 className='title-span'>Generic API Folders</h1>
                                    {
                                        folderList.length > 0 ?
                                            folderList.filter((val)=>(
                                                val.toLowerCase().includes(searchQuery2.toLowerCase())
                                            )).map((obj, i)=>(
                                                <Grid.Column key={i} style={{padding: '0 1rem'}}>
                                                    <ModalComponent size='large' content={FolderViewContent} type='Folder' triggerName={obj} triggerIcon='folder'/>
                                                </Grid.Column>
                                            ))
                                        :
                                        <p style={{color: '#aeaeae'}}>No items found</p>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid.Row>
                    
                    <Grid.Row style={{paddingTop: '30px'}}>
                        <Container>
                            {accList == null ?
                                [...Array(4)].map((v,i)=>(
                                    <div key={i} style={{width: '100%', marginBottom:'12px', height: '50px', borderRadius:'4px'}} className='skeleton2'></div>
                                ))
                            :
                                <>
                                <h1 className='title-span' style={{marginBottom: '20px'}}>AWS Accounts</h1>
                                    
                                {accList.length > 0 ?
                                    <Accordion fluid styled style={{boxShadow: 'none'}}>
                                        {accList.filter((val)=>(
                                            val.acc_name.toLowerCase().includes(searchQuery2.toLowerCase())
                                        )).map((acc_obj, index) => (
                                            <React.Fragment key={index}>
                                                <Accordion.Title active={activeIndex === index} index={index} onClick={handleClick} className='accordion-title'>
                                                    <Icon name='dropdown' /> {acc_obj.acc_name}
                                                </Accordion.Title>

                                                <Accordion.Content active={activeIndex === index}>
                                                    {accordianLoader === true ?
                                                        [...Array(3)].map((v,i)=>(
                                                            <div key={i} style={{width: '100%', marginBottom:'12px', height: '60px', borderRadius:'4px'}} className='skeleton2'></div>
                                                        ))
                                                    :
                                                    <>
                                                        <div className='accordian-head'>
                                                            <SearchBoxComponent txtClr='#777777' width='250px' searchEvent={setSearchQuery} />
                                                            <span style={{paddingLeft: "2rem"}}></span>
                                                            <ModalComponent content={DeleteAccountContent} type='deleteAccount' header='Delete' triggerName='Remove Account' triggerIcon='trash' acc_name={acc_obj.acc_name} acc_id={acc_obj.account}/>
                                                        </div>
                                                        
                                                        {
                                                            accAPIList.filter((val)=>(
                                                                val.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                                                val.id.toLowerCase().includes(searchQuery.toLowerCase())
                                                            )).map((obj, i) => (
                                                                <div key={i} className='mini-card'>
                                                                    <span style={{fontWeight:'bold'}}>{obj.title}</span>&emsp;<span style={{color:'#999', fontSize:'12px'}}>{obj.id}</span>
                                                                    {obj.stage.map((stage, i) =>(
                                                                        <span key={i} style={{display: 'flex', justifyContent: 'space-between', margin:'5px 0'}}>
                                                                            <Checkbox label={stage.stage} data-acc_id={acc_obj.account} data-api_id={obj.id} 
                                                                                data-stage={stage.stage} onChange={apiCheckboxHandler} disabled={disableCheckbox} 
                                                                                checked={stage.enabled}
                                                                            />
                                                                            &nbsp;
                                                                            {/* <ModalComponent content={DocumentationContent} type='addDocs' header='Documentation' 
                                                                                triggerName='Documentation' triggerIcon='remove' acc_name={acc_obj.name} api_id={obj.id} 
                                                                                stage={stage.stage}
                                                                            /> */}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                    }
                                                </Accordion.Content>
                                            </React.Fragment>
                                        ))}
                                    </Accordion>
                                :
                                    <p style={{color: '#aeaeae', marginLeft: '15px'}}>No account found</p>
                                }
                            </>}
                        </Container>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }else{
        return <></>
    }
}

export default observer(AccountsManagement)
