/* eslint-disable */
import React from "react"
import { useEffect } from "react"
import { useState } from "react"

// css
import './apistyle.css'

// state
import { store } from 'services/state.js'

// client credentials
import { apiGatewayClientWithCredentials } from 'services/api'
// semantic
import { Container, Grid, Icon } from "semantic-ui-react"
import SearchBoxComponent from "components/SearchBox/SearchBoxComponent"
import { useNavigate } from "react-router-dom"
import jwt_decode from "jwt-decode";
import { getUser } from 'services/self'


export default function AWSAccountsList (props) {
    const BrevilleInternalGroup = 'breville-apihub-user'
    const AdminGroup = 'breville-apihub-admin'

    const navigate = useNavigate()

    const [accList, setAccList] = useState(null)
    const [folderList, setFolderList] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [keyval, setKey] = useState(0)

    function getUserCgGroup () { return jwt_decode(store.idToken)['cognito:groups'] || '' }

    const api_callback = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(res => {
            if(params.type == 'getAccountList'){
                setAccList(res.data)
            }else if(params.type == 'get_all_folders'){
                setFolderList(res.data)
            }
        },err=>{
            console.log(err.data)
        })
    }

    useEffect(()=>{
        if(getUserCgGroup().includes(BrevilleInternalGroup) || getUserCgGroup().includes(AdminGroup)){
            api_callback({'type': 'getAccountList'}, '/accounts')
            api_callback({'type': 'get_all_folders'}, '/apidocs')
        }
    }, [])

    // reload owl carousel
    useEffect(()=>{ setKey(Math.floor(Math.random() * 10)) },[searchQuery])

    if(getUser()){
        if(getUserCgGroup().includes(BrevilleInternalGroup) || getUserCgGroup().includes(AdminGroup) ){
            return(
                <>
                    <div style={{display:'flex', justifyContent:'center', marginTop:'30px'}}>
                        <SearchBoxComponent txtClr='#777777' width='250px' bg='transparent' searchEvent={setSearchQuery}/>
                    </div>
                    <Grid style={{marginTop: '20px'}}>
                        <Grid.Row>
                            <Grid doubling container columns={10}>
                                {folderList == null ?
                                    [...Array(10)].map((obj, i)=>(
                                        <Grid.Column key={i}>
                                            <div style={{width: '80px', height: '80px', borderRadius:'4px'}} className='skeleton2'></div>
                                        </Grid.Column>
                                    ))
                                :
                                <>
                                    {folderList.length > 0 && <h1 className='title-span'>Generic API Folders</h1>}
                                    {
                                        folderList.length > 0 &&
                                            folderList.filter((val)=>(
                                                val.toLowerCase().includes(searchQuery.toLowerCase())
                                            )).map((obj, i)=>(
                                                <Grid.Column key={i} style={{padding: '0 1rem'}}>
                                                    <div className='folder-box' onClick={()=>{store.selectedFolderName=obj; navigate("/api/docs")}}>
                                                        <Icon name='folder' size='huge' color="grey"/>{obj}
                                                    </div>
                                                </Grid.Column>
                                            ))
                                    }
                                </>
                                }
                            </Grid>
                        </Grid.Row>
    
                        <Grid.Row style={{marginTop: '20px'}}>
                            <Grid doubling container columns={4}>
                            {accList == null ?
                                [...Array(1)].map((v, i)=>(
                                    <div key={i} style={{width: '100%', marginBottom:'12px', height: '80px', borderRadius:'4px'}} className='skeleton2'></div>
                                ))
                            :
                            <>
                                {accList.length > 0 && <h1 className='title-span'>AWS Account(s)</h1>}
                                {accList.length > 0 &&
                                    accList.filter((val)=>(
                                        val.acc_name.toLowerCase().includes(searchQuery.toLowerCase())
                                    )).map((acc_obj, i) => (
                                        <Grid.Column key={i}>
                                            <Container className='containerBox' onClick={()=>{store.selectedAccount=acc_obj; navigate("/api/docs")}}>
                                                <p className="inner_p_tag">{acc_obj.acc_name}</p>
                                            </Container>
                                        </Grid.Column>
                                    ))
                                }
                            </>
                            }
                            </Grid>
                        </Grid.Row>
                    </Grid>
                </>
            )
        }else{
            console.log('Redirecting to API docs...')
            navigate('/api/docs')
            return <></>
        }
    }else{
        navigate('/')
        return <></>
    }
}
