import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Confirm, Dropdown, Header, Icon, Label, Table } from 'semantic-ui-react'
import { apiGatewayClientWithCredentials } from 'services/api'

function UpdateGroupMapping() {
    const renderLabel = (label) => ({
        color: 'grey',
        content: label.text,
    })

    const [selectedAPIs, setSelectedAPIs] = useState([])
    const [apiListDropdownOptions, setApiListDropdownOptions] = useState([])
    
    const [apiStore, setApiStore] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    const [mappingData, setMappingData] = useState([])
    const [deleteBtn, setDeleteBtn] = useState(false)
    const [updateBtnLoad, setUpdateBtnLoad] = useState(false)

    const fetch_acc_apis = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                let response = res.data
                setApiStore(res.data)
                setApiListDropdownOptions(
                    response.api_list.map((obj)=>{
                        return { key: obj.id, text: obj.title, value: obj.id, content: (<Header as="h4" content={obj.title} subheader={obj.id} />) }
                    })
                )
            },
            err=>{
                console.log(err)
            }
        )
    }

    const update_mapping_data = async (params, path) => {
        setUpdateBtnLoad(true)
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                let response = res.data
                if(response === 'updated'){
                    fetch_table_data({type: 'fetch_mapping'}, '/mapping')
                    alert('Mapping successfully updated.')
                }
                setUpdateBtnLoad(false)
            },
            err=>{setUpdateBtnLoad(false)}
        )
    }

    const fetch_table_data = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                setMappingData(res.data);
            },
            err=>{
                console.log(err)
            }
        )
        setDeleteBtn(false)
    }

    const delete_mapping = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.delete(path, {}, {...params}, {}))
        .then(
            res => {},
            err=>{console.log(err.data)}
        )
        fetch_table_data({type: 'fetch_mapping'}, '/mapping')
    }

    const editBtnClick = (table_row) => {
        let api_meta = table_row.api_meta
        setSelectedRow(table_row)
        fetch_acc_apis({type: 'getAccAPIList', account: table_row.account_id}, '/accounts')
        setSelectedAPIs(api_meta.map(api => api.id))
    }

    const deleteBtnClick = (row) => {
        setDeleteBtn(true)
        delete_mapping({type: 'delete_mapping', 'accountId': row.account_id, 'groupName': row.group_name}, '/mapping')
        setSelectedAPIs([])
        setApiListDropdownOptions([])
    }

    const updateBtnClick = () => {
        let api_meta = apiStore.api_list.filter((obj) => { return selectedAPIs.includes(obj.id) })
        update_mapping_data({type: 'create_mapping', flag: 'update', 'selectedGroup': selectedRow.group_name, 'selectedAccountId': selectedRow.account_id, 'selectedAccountName': selectedRow.account_name, apiMeta: api_meta}, '/mapping')
    }

    useEffect(()=>{
        fetch_table_data({type: 'fetch_mapping'}, '/mapping')
    }, [])

    return (
        <div style={{ width: '100%', maxWidth: "950px" }}>
            {apiListDropdownOptions.length > 0 && 
                <div style={{ marginBottom: '3rem'}}>
                    <Dropdown
                        style={{ margin: "1rem 0", }}
                        options={apiListDropdownOptions}
                        placeholder='Choose API'
                        search
                        selection
                        fluid
                        multiple
                        clearable
                        value={selectedAPIs}
                        renderLabel={renderLabel}
                        onChange={(e, { value }) => { setSelectedAPIs(value) }}
                    />
                    <Button primary loading={updateBtnLoad} disabled={updateBtnLoad} onClick={()=>updateBtnClick()}><Icon name='save' /> Update</Button>
                </div>
            }
            <Table celled fixed size='small'>
                <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Group Name</Table.HeaderCell>
                        <Table.HeaderCell>Account Id</Table.HeaderCell>
                        <Table.HeaderCell>API List</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { mappingData && mappingData.data ?
                        mappingData.data.map((row, r_idx)=>(
                            <Table.Row key={r_idx} style={{textAlign:'center'}}>
                                <Table.Cell>{row.group_name}</Table.Cell>
                                <Table.Cell>{row.account_id}</Table.Cell>
                                <Table.Cell>
                                    {
                                        row.api_meta ?
                                            row.api_meta.map((api) => (
                                                <Label key={api.id} color="black" size="small" circular style={{ margin: '2px', fontWeight: '500' }}>{api.title}</Label> 
                                            ))
                                        : null
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Button size="mini" circular title="Edit" color="black" icon='edit' onClick={()=>{editBtnClick(row)}} style={{ marginRight: "10px" }}>
                                            Edit
                                        </Button>
                                        <Button size="mini" circular title="Delete" color="gray" icon='delete' disabled={deleteBtn} onClick={()=>{deleteBtnClick(row)}}>
                                            Delete
                                        </Button>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))
                        : null
                    }
                </Table.Body>
            </Table>
        </div>
    )
}

export default UpdateGroupMapping