import React from 'react'

// semantic-ui
import { Menu, Segment, Sidebar } from 'semantic-ui-react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { getUser } from 'services/self'


const Admin = () => {
    const navigate = useNavigate()
    const AWS_SEC_BTN_T_1 = "Account Management"
    const GROUP_SEC_BTN_T_1 = "Create Group Mapping"
    const GROUP_SEC_BTN_T_2 = "Update Group Mapping"

    if(getUser()){
        return (
          <div className="base">
              <div style={{height: 'inherit'}}>
                  <Sidebar.Pushable as={Segment}
                      style={{
                          display: 'flex',
                          flex: '1 1 auto',
                          overflow: 'hidden',
                          border: 'none',
                          margin: 0,
                          borderRadius: 0,
                          boxShadow: 'none'
                      }}
                  >
                      <Sidebar as={Menu}
                          // inverted
                          attached
                          borderless
                          vertical
                          visible
                          style={{
                              margin: 0,
                              borderRadius: 0,
                              flex: '0 0 auto',
                              position: 'relative',
                              overflowX: 'hidden',
                              overflowY: 'scroll',
                              scrollbarWidth: 'thin',
                              width: '260px'
                          }}
                      >
                          <Menu vertical style={{width: "100%", border: "none"}}>
                              <br/>
                              <Menu.Item>
                                  <Menu.Header style={{fontSize: "1.1rem"}}>AWS Account(s)</Menu.Header>
                                  <Menu.Menu>
                                      <NavLink to=""><Menu.Item>{AWS_SEC_BTN_T_1}</Menu.Item></NavLink>
                                  </Menu.Menu>
                              </Menu.Item>
                              <Menu.Item>
                                  <Menu.Header style={{fontSize: "1.1rem"}}>Group & API mapping</Menu.Header>
                                  <Menu.Menu>
                                      <NavLink to="create-mapping"><Menu.Item>{GROUP_SEC_BTN_T_1}</Menu.Item></NavLink>
                                      <NavLink to="update-mapping"><Menu.Item>{GROUP_SEC_BTN_T_2}</Menu.Item></NavLink>
                                  </Menu.Menu>
                              </Menu.Item>
                          </Menu>
                      
                      </Sidebar>
                  </Sidebar.Pushable>
              </div>
      
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <h2 style={{ padding: "15px 0", color: "#313638"}}>
                      {
                          window.location.pathname === "/admin" ? AWS_SEC_BTN_T_1 :
                          window.location.pathname === "/admin/create-mapping" ? GROUP_SEC_BTN_T_1 :
                          window.location.pathname === "/admin/update-mapping" ? GROUP_SEC_BTN_T_2 :
                          ''
                      }
                  </h2>
                  <Outlet/>
              </div>
      
          </div>
        )
    }else{
        navigate('/')
        return <></>
    }
}

export default Admin