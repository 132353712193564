/* eslint-disable */
import React, { useEffect, useState } from "react"
import jwt_decode from "jwt-decode";
import AWS from 'aws-sdk'

// css
import './apistyle.css'

// client credentials
import { apiGatewayClientWithCredentials } from 'services/api'

// semantic-ui
import { Menu, Segment, Sidebar, Button, Icon, Search, Accordion, Loader, Dimmer, Container, Item } from 'semantic-ui-react'

// state
import { store } from 'services/state.js'

// swagger-ui
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

// components
import SearchBoxComponent from "components/SearchBox/SearchBoxComponent"
import { useNavigate } from "react-router-dom"
import { getUser } from 'services/self'


export default function AWSAPIView(props){
    const [apiList, setApiList] = useState([])
    const [categoryList, setCategoryList] = useState({})
    const [apiSpec, setApiSpec] = useState(undefined)
    const [loaderState, setLoaderState] = useState(false)
    const [apiLoaderState, setApiLoaderState] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedIndex, setSelectedIndex] = useState(-1)
    var [filter, setFilter] = useState('category')
    var [categorySearch, setCategorySearch] = useState({})
    var [sortingState, setSorting] = useState('none')
    var [selectedStage, setSelectedStage] = useState('')
    // accordian
    const [activeIndex, setActiveIndex] = useState(-1)
    const [activeIndex2, setActiveIndex2] = useState(-1)

    const navigate = useNavigate()

    function getUserCgGroup () { return jwt_decode(store.idToken)['cognito:groups'] || '' }

    useEffect(() => {
        if(store.selectedAccount){
            getSubscribedAPIs({type: 'getSubscribedAPIs', acc_id: store.selectedAccount.account, cg_groups: jwt_decode(store.idToken)['cognito:groups']}, '/accounts')
        }else if(store.selectedFolderName){
            getSubscribedAPIs({type: 'get_custom_apispec_file_list', folderName: store.selectedFolderName}, '/apidocs')
            setFilter('default')
        }else if( getUserCgGroup().some(item => item.startsWith('partner-')) ){
            getSubscribedAPIs({type: 'getSubscribedAPIs', cg_groups: jwt_decode(store.idToken)['cognito:groups']}, '/accounts')
        }
    }, [navigate])

    const createCategoryAPIList = (apis) => {
        if(apis.length > 0){
            if(apis[0].hasOwnProperty('file')){
                return
            }
        }
        var category_list = {}
        for(const obj of apis){
            let category = obj.tags.category
            let subcategory = obj.tags.subcategory
            
            if(!subcategory){
                if(category_list.hasOwnProperty(category)){
                    category_list[category] = [...category_list[category], obj]
                }else{
                    category_list[category] = [obj]
                }   
            }else{
                if(category_list.hasOwnProperty(category)){
                    if(category_list[category].hasOwnProperty(subcategory)){
                        category_list[category][subcategory] = [...category_list[category][subcategory], obj]
                    }else{
                        category_list[category] = {...category_list[category], [subcategory]: [obj]}
                    }
                }else{
                    category_list[category] = {[subcategory]: [obj]}
                }   
            }
        }
        const ordered_category_list = Object.keys(category_list).sort().reduce(function (result, key) {
            result[key] = category_list[key];
            return result;
        }, {})
        setCategoryList(ordered_category_list)
        setCategorySearch(ordered_category_list)
    }

    const getSubscribedAPIs = async (params, path) => {
        setApiLoaderState(true)
        setLoaderState(true)
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(res => {
            setApiList(res.data['api_list'])
            createCategoryAPIList(res.data['api_list'])
        },err=>{
            console.log(err.data)
        })
        setLoaderState(false)
        setApiLoaderState(false)
    }

    const handleClick = async (e, category, props) => {
        const index = category
        let newIndex;
        for(const element of document.querySelectorAll('.category-accordion')){
            element.style.backgroundColor  = '#fff'
            element.style.color = '#313638'
        }
        let ele = document.getElementById(props.id)
        if(activeIndex === index){
            newIndex = -1
        }else{
            newIndex = index
        }
        setActiveIndex(newIndex)
        setActiveIndex2(-1)
        setSelectedMenu(undefined)
    }

    const handleClick2 = async (e, props) => {
        const index = props.index
        const newIndex = activeIndex2 === index ? -1 : index
        setActiveIndex2(newIndex)
        setSelectedMenu(undefined)
    }

    const sortingHandler = () => {
        if(sortingState == 'none'){
            setSorting('block')
        }else{
            setSorting('none')
        }
    }

    const sortingOptionHandler = (val) => {
        setFilter(val)
        setSorting('none')
        setSearchQuery('')
    }

    const get_s3_api_yaml = (api_id, stage, fileName, key_path) => {
        return new Promise(resolve => {
            const s3 = new AWS.S3();

            s3.getObject({Bucket: window.config.swaggerStorageBucket, Key: key_path}, (err, data) => {
                if (err) {
                    console.log(err);
                    resolve(undefined)
                } else {
                    resolve(data.Body.toString('utf-8'));
                }
            })
        });
    }

    const getAPIspec = async (api_id, stage, key_path) => {
        setApiLoaderState(true)
        setSelectedMenu(stage)
        await get_s3_api_yaml(api_id, stage, null, key_path).then(data => setApiSpec(data))
        setApiLoaderState(false)
    }

    const getFileApiSpec = async (fileName, index, key_path) => {
        setSelectedIndex(index)
        setApiLoaderState(true)
        await get_s3_api_yaml(null, null, fileName, key_path).then(data => setApiSpec(data))
        setApiLoaderState(false)
    }

    const backBtn = () => {
        if(store.selectedAccount || store.selectedFolderName){
            navigate(-1)
        }else{
            navigate('/')
        }
        store.selectedAccount=undefined
        store.selectedFolderName=undefined
    }

    // categorical search filter
    const categroyFilter = (obj, search_txt) => {
        let result = {}
        for (const [category, category_objs] of Object.entries(obj)){
            if(Array.isArray(category_objs)){
                // uncategorized objs
                let filter_lst = category_objs.filter(obj=>obj.title.toLowerCase().includes(search_txt))
                filter_lst.length>0 ? result[category] = category_objs.filter(obj=>obj.title.toLowerCase().includes(search_txt)) : null
            }else{
                // categorized objs
                for (const [subcategory, subcategory_objs] of Object.entries(category_objs)){
                    let filter_lst = subcategory_objs.filter(obj=>obj.title.toLowerCase().includes(search_txt))
                    filter_lst.length>0 ? result[category] = {...result[category], ...{[subcategory] : filter_lst}} : null
                    
                }
            }
        }
        setCategorySearch(result)
    }

    // categorical search filter
    useEffect(()=>{
        if(searchQuery && filter == 'category'){
            categroyFilter(categoryList, searchQuery)
        }else{
            setCategorySearch(categoryList)
        }
        setActiveIndex2(-1)
    }, [searchQuery])

    if(getUser()){
        return(
            <div className="base">
                <div style={{height: 'inherit'}}>
                    <Sidebar.Pushable as={Segment}
                        style={{
                            display: 'flex',
                            flex: '1 1 auto',
                            overflow: 'hidden',
                            border: 'none',
                            margin: 0,
                            borderRadius: 0,
                            boxShadow: 'none'
                        }}
                    >
                        <Sidebar as={Menu}
                            // inverted
                            attached
                            borderless
                            vertical
                            visible
                            style={{
                                margin: 0,
                                borderRadius: 0,
                                flex: '0 0 auto',
                                position: 'relative',
                                overflowX: 'hidden',
                                overflowY: 'scroll',
                                scrollbarWidth: 'thin',
                                width: '260px'
                            }}
                        >
                            <div style={{margin:'7px', display:'flex'}}>
                                <SearchBoxComponent width='215px' bg='transparent' txtClr='#313638' searchEvent={setSearchQuery}/>
                            </div>
                            
                            <div className='sorting-container sorting-spacing2' onClick={sortingHandler}>
                                <span style={{color: '#313638'}}>Order by&nbsp;:&nbsp;</span>
                                <div style={{cursor:'pointer'}} className='sorting-content'>
                                    <Icon name='sort amount down' color='grey'/>
                                    <b style={{color: '#313638', textTransform: 'capitalize'}}>{filter}</b>
                                    <div className='sorting-dd'>
                                        <span className='sorting-dd-text' style={{display:sortingState}} onClick={()=>sortingOptionHandler('category')}>Category</span>
                                        <span className='sorting-dd-text' style={{display:sortingState}} onClick={()=>sortingOptionHandler('default')}>Default</span>
                                    </div>
                                </div>
                            </div>
                            <Segment style={{padding: '0', border: 'none !important'}}>
                                {/* skeleton loader */}
                                <div style={{display: loaderState ? 'block' : 'none'}}> 
                                    {[...Array(3)].map((v, i)=>(
                                        <div key={i} style={{ margin:'15px 10px', height: '4rem', borderRadius:'4px'}} className='skeleton'></div>
                                    ))}
                                </div>
                                    {filter === "default" ?
                                        apiList.length>0 ? apiList.filter((val)=>(
                                            val.title ? val.title.toLowerCase().includes(searchQuery.toLowerCase()) : val.file.toLowerCase().includes(searchQuery.toLowerCase())
                                        )).map((obj, index) => (
                                            obj.hasOwnProperty('file') ?
                                                    <Item as='a' onClick={()=>getFileApiSpec(obj.file, index, obj.key_path)} index={index} style={{backgroundColor: selectedIndex==index ? 'rgb(79, 39, 76)': '#fff', color: selectedIndex==index? '#fff':'#000'}}>
                                                        <Item.Description>
                                                            {obj.file.substring(0, obj.file.lastIndexOf("."))}&nbsp;-&nbsp;
                                                            <b style={{fontSize: '0.9rem', color: obj.file.substring(obj.file.lastIndexOf(".")+1, obj.file.length).toLowerCase()==='json' ? 'tomato' : 'green'}}>
                                                                {obj.file.substring(obj.file.lastIndexOf(".")+1, obj.file.length).toUpperCase()}
                                                            </b>
                                                        </Item.Description>
                                                    </Item>
                                            : obj.hasOwnProperty('title') ?
                                                <>
                                                    <div className="api-card" key={obj.uid}>
                                                        <p className="api-title">{obj.title}</p>
                                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                                            {obj.stage.map((stage, i)=>(
                                                                <div
                                                                    key={i} 
                                                                    id={`${obj.id}-${stage.name}`}
                                                                    onClick={(e)=>{
                                                                        setSelectedStage(`${obj.id}-${stage.name}`);
                                                                        getAPIspec(obj.id, stage.name, stage.key_path)}
                                                                    }
                                                                    className={selectedStage === `${obj.id}-${stage.name}` ? "api-stage active-stage" : "api-stage"}
                                                                >
                                                                    {stage.name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                null
                                            ))
                                        : 
                                            <p style={{display: loaderState ? 'none' : 'block', color:'#e2e2e2', textAlign: 'center'}}>No API</p>
                                    :   
                                        <Accordion fluid>
    
                                            {Object.keys(categorySearch).length > 0 ? 
                                                Object.keys(categorySearch).map((category, index)=>(
                                                
                                                <div style={{marginBottom: '0px', border: 'none'}}>
                                                    <Accordion.Title 
                                                        active={activeIndex === category} index={index} onClick={(e, props)=>handleClick(e, category, props)} 
                                                        id={`id-${category.replace(/ /g, '-')}-category`} className='category-accordion' 
                                                        style={activeIndex === category ? {backgroundColor: '#50284d', color: '#fff'} : {backgroundColor: '#fff', color: '#313638'}}
                                                    >
                                                        {category === "uncategorized" ? "Others" : category} <Icon name='dropdown'/>
                                                    </Accordion.Title>
                                                    {category === 'uncategorized' ?
                                                        <Accordion.Content className='accordion-content' active={activeIndex === category}>
                                                        {categorySearch[category].map((apiObj,index)=>(
                                                            <div className="api-card">
                                                                <p className="api-title">{apiObj.title}</p>
                                                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                                                    {apiObj.stage.map((stage, i)=>(
                                                                    <div 
                                                                        id={`${apiObj.id}-${stage.name}`}
                                                                        key={i} 
                                                                        onClick={(e)=>{
                                                                            setSelectedStage(`${apiObj.id}-${stage.name}`);
                                                                            getAPIspec(apiObj.id, stage.name, stage.key_path)
                                                                        }}
                                                                        className={selectedStage === `${apiObj.id}-${stage.name}` ? "api-stage active-stage" : "api-stage"}
                                                                    >
                                                                        {stage.name}
                                                                    </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        </Accordion.Content>
                                                    :
                                                        <Accordion.Content className='accordion-content' active={activeIndex === category}>
                                                            {Object.keys(categorySearch[category]).map((subcategory, index2)=>(
                                                            <Accordion style={{marginTop: '10px'}}>
                                                                <Accordion.Title className='sub-accoridon' active={activeIndex2 === index2} index={index2} onClick={handleClick2} style={{ color: '#313638', fontSize: '12px' }}>
                                                                    {subcategory} <Icon name='dropdown' />
                                                                </Accordion.Title>
                                                                <Accordion.Content active={activeIndex2 === index2}>
                                                                    {categorySearch[category][subcategory].map((subApiObj, i)=>(
                                                                    <div className="api-card" style={{margin: '0px 10px 5px 10px'}}>
                                                                        <p className="api-title">{subApiObj.title}</p>
                                                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                                                            {subApiObj.stage.map((stage, i)=>(
                                                                                <div 
                                                                                    key={i} 
                                                                                    id={`${subApiObj.id}-${stage.name}`}
                                                                                    onClick={(e)=>{
                                                                                        setSelectedStage(`${subApiObj.id}-${stage.name}`);
                                                                                        getAPIspec(subApiObj.id, stage.name, stage.key_path)
                                                                                    }}
                                                                                    className={selectedStage === `${subApiObj.id}-${stage.name}` ? "api-stage active-stage" : "api-stage"} 
                                                                                >
                                                                                    {stage.name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    ))}
                                                                </Accordion.Content>
                                                            </Accordion>
                                                            ))}
                                                        </Accordion.Content>
                                                    }
                                                </div>
                                            ))
                                            : 
                                            null
                                            }
                                        </Accordion>
                                    }
                            </Segment>
    
                        </Sidebar>
                    </Sidebar.Pushable>
                </div>
                
                <div style={{display: 'block', width: '100%'}}>
                    <div className="sub-header">
                        <Button style={{backgroundColor: '#4F274C', color:'#DCD4DB'}} onClick={()=>backBtn()}>
                            <Icon name='arrow left' style={{color:'#fff'}}/> Back
                        </Button>
                        <h3 style={{margin: '0'}}>{store.selectedAccount ? store.selectedAccount.acc_name : store.selectedFolderName}</h3>
                    </div>
                    
                    {apiLoaderState ?
                        <div className="swagger-container" style={{padding:'10px'}}>
                            <div style={{height: '150px'}} className='skeleton2'></div>
                            <div style={{display:'flex', justifyContent:'space-between', margin:'30px 0'}}>
                                <div style={{width: '200px', height:'50px'}} className='skeleton2'></div>
                                <div style={{width: '200px', height:'50px'}} className='skeleton2'></div>
                            </div>
                            <div>
                                {
                                    [...Array(8)].map((v,i)=>(
                                        <div key={i} style={{height: '50px', marginBottom: '15px'}} className='skeleton2'></div>
                                    ))
                                }
                            </div>
                        </div>
                    :
                    null
                    }
                    <div className="swagger-container" style={{display: apiLoaderState? 'none': 'block'}}>
                        {apiSpec ? 
                            <SwaggerUI spec={apiSpec} /> 
                            : 
                            <h6 style={{
                                textAlign: 'center', 
                                fontSize: '1.2rem', 
                                fontWeight: '600',
                                paddingTop: '50px'
                            }}>
                                Please select an API to view.
                            </h6>
                        }
                    </div>
                </div>
            </div>
        )
    }else{
        navigate('/')
        return <></>
    }
}