/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dimmer, Dropdown, Form, Grid, Icon, Item, Loader, Table } from 'semantic-ui-react'
// css
import "./accountManagement.css"
// state
import { store } from 'services/state.js'
// client credentials
import { apiGatewayClientWithCredentials } from 'services/api'

import ModalComponent from './ModalComponent'
import SearchBoxComponent from 'components/SearchBox/SearchBoxComponent'
import jsyaml from "js-yaml"


export function DeleteAccountContent(props){
    return <p>Are you sure?</p>
}

export function CreateFolderContent(props){
    return (
        <Form>
            <FormInputField required={true} label='Folder Name' msg='Please enter the folder name' storeKey='createFolder'/>
        </Form>
    )
}

export function PublishContent(props){
    return (
        <Form>
            <FormInputField required={true} label='Enter Version' msg='Please enter the version' storeKey='publishVersion'/>
        </Form>
    )
}


export function FormInputField(props){
    useEffect(()=>{

    },[])

    const [errorMsg, setErrorMsg] = useState({})    
    
    const inputHandler = (e, msg ) => {
        if(props.required){
            if(!e.target.value){
                setErrorMsg({content: msg})
            }else{
                setErrorMsg({})
            }
        }
    }

    return (
        <Form.Input
            required = {props.required}
            label={props.label}
            placeholder={props.placeholder ? props.placeholder : props.label}
            fluid
            error={errorMsg.hasOwnProperty('content') ? errorMsg : null}
            onBlur={(e)=>inputHandler(e, props.msg)}
            onChange={(e, data) => {store.accountManagement[props.storeKey]=e.target.value.trim()}}
        />
    )
}

export function AddAccountContent(props){
    return (
        <>
            <Form>
                <FormInputField required={true} placeholder='integrations-prod' label='Account Name' msg='Please enter the account name' storeKey='accountName'/>
                <FormInputField required={true} placeholder='arn:aws:iam::<acc_id>:role/<role_name>' label='IAM Role ARN' msg='Please enter the IAM role arn' storeKey='roleARN'/>
            </Form>
            <br></br>
            <p style={{color: '#777'}}><b>Note:</b> Please note that in some cases, it may take a couple of minutes to display your AWS account.</p>
        </>
    )
}

export function FolderViewContent(props){
    useEffect(()=>{
        if(store.accountManagement['openedFoldername'] !== undefined){
            api_callback()
        }
    },[store.accountManagement['openedFoldername']])

    const [filenames, setFilenames] = useState(null)
    const [removeFileId, setRemoveFileId] = useState([])
    const fileInput = React.createRef()
    const [searchQuery, setSearchQuery] = useState('')
    const [btnLoader, setBtnLoader] = useState(false)
    const [cblist, setCblist] = useState([])

    const api_callback = async () => {
        let dataset = {type:'get_custom_apispec_file_list', folderName: store.accountManagement['openedFoldername']}
        await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, dataset, {}))
        .then(
            res => {
                setFilenames(res.data['api_list'])
            },
            err=>{
                alert(err.data)
            }
        )
    }

    const getFileForRemove = (e, cb_name) => {
        if(e.target.checked == true){
            setCblist([...cblist, cb_name])
            setRemoveFileId([...removeFileId, e.target.getAttribute('data-key')])
        }else{
            cblist.splice(cblist.indexOf(cb_name), 1)
            setCblist([...cblist])
            removeFileId.splice(removeFileId.indexOf(e.target.getAttribute('data-key')), 1)
            setRemoveFileId([...removeFileId])
        }
    }

    const uploadAPISpec = (e) => {
        const files = fileInput.current.files
        let swagger, swaggerObject
        if (files.length > 0) {
            [].forEach.call(files, file => {
                const reader = new window.FileReader()

                reader.onload = async (e) => {
                    if (file.name.includes('json')) {
                        swaggerObject = JSON.parse(e.target.result)
                        swagger = JSON.stringify(swaggerObject)
                    }
                    else if(file.name.includes('yaml')) {
                        swaggerObject = jsyaml.load(e.target.result)
                        swagger = JSON.stringify(swaggerObject)
                    }
                    else{
                        alert(`${file.name} is not a valid file`)
                    }
                    
                    let dataset = {type: 'fileUpload', swagger: swagger, filename: file.name, folderName: store.accountManagement['openedFoldername']}
                    setBtnLoader({...btnLoader, uploadBtn: true})

                    await apiGatewayClientWithCredentials().then((app) => app.post('/apidocs', {}, dataset, {}))
                    .then(
                        (res) => {
                            console.log(res.data)
                            api_callback()
                        },
                        (err) => {
                            console.log(err.data)
                        }    
                    )
                    setBtnLoader({...btnLoader, uploadBtn: false})
                }
                reader.readAsText(file)
            })
        }
    }

    const removeDocsHandler = async () => {
        if(removeFileId){
            let dataset = {type: 'fileDelete', filename: removeFileId, folderName: store.accountManagement['openedFoldername']}
            setBtnLoader({...btnLoader, removeBtn: true})

            await apiGatewayClientWithCredentials().then((app) => app.post('/apidocs', {}, dataset, {}))
            .then(
                (res) => {
                    setRemoveFileId([])
                    api_callback()
                    document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
                    setCblist([])
                },
                (err) => {
                    console.log(err.data)
                }    
            )
            setBtnLoader({...btnLoader, removeBtn: false})
        }
    }

    return(
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '8px', borderBottom: '1px solid #d2d2d2'}}>
                <input 
                    type="file" 
                    accept='.json,.yaml,.yml' 
                    id='inp_fileUpload' 
                    onChange={(e)=>{fileInput.current.files.length>0 ? uploadAPISpec(e) : null}} 
                    multiple ref={fileInput} style={{display: 'none'}}
                />
                <Button loading={btnLoader.uploadBtn} onClick={()=>document.getElementById('inp_fileUpload').click()} content='Submit' primary size='mini'>
                    <Icon name='upload'/> Upload
                </Button>
                <ModalComponent size='tiny' content={DeleteAccountContent} type='deleteFolder' header='Delete Folder' triggerName='Delete Folder' triggerIcon='trash' folderName={store.accountManagement['openedFoldername']}/>
            </div>

            <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '10px'}}>{store.accountManagement['openedFoldername']}</p>
            
            {filenames == null ? 
                <p style={{textAlign: 'center', color: '#a0a0a0'}}>Loading...</p>
            : filenames.length>0?
                <>  
                    <div style={{display:'flex', justifyContent:'space-between', margin: '25px 0 0 0'}}>
                        <Button 
                            loading={btnLoader.removeBtn} 
                            basic size='mini' onClick={removeDocsHandler} 
                            style={{boxShadow: 'none'}} content='Standard'
                            disabled={removeFileId.length > 0 ? false : true}
                        >
                            <Icon name='trash'/> Delete
                        </Button>
                        <SearchBoxComponent txtClr='#777777' width='250px' bg='transparent' searchEvent={setSearchQuery}/>
                    </div>

                    <Table selectable style={{margin:'5px 0'}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Filename</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Size</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {filenames.filter((val)=>(
                                val.file.toLowerCase().includes(searchQuery.toLowerCase())
                            )).map((obj, i)=>(
                                <Table.Row key={i}>
                                    <Table.Cell style={{display: 'flex', alighItems:'center'}}>
                                        <input 
                                            type='checkbox' 
                                            checked={cblist.includes(`cb_${obj.file}`) ? true : false} 
                                            data-key={obj.file} 
                                            onChange={(e)=> getFileForRemove(e, `cb_${obj.file}`)}
                                        />&nbsp;
                                        <p>{obj.file.substring(0, obj.file.lastIndexOf("."))}</p>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <p>{obj.file.substring(obj.file.lastIndexOf(".")+1, obj.file.length)}</p>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {obj.size}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {/* <ModalComponent content={DocumentationContent} type='addGenericDocs' header='Documentation'
                                            triggerName='Documentation' triggerIcon='remove' fileName={obj.file}
                                        /> */}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            : <p style={{textAlign: 'center', color: '#a0a0a0'}}>No files in this folder</p>
            }
        </div>
    )
}

export function DocumentationContent(props){
    const [inputControl, setInputControl] = useState({})
    const [collectedInputs, setCollectedInputs] = useState({})
    const [saveBtnLoader, setSaveBtnLoader] = useState(false)
    const [tableData, setTableData] = useState([])
    const [removeDocsId, setRemoveDocsId] = useState([])
    const [removeBtnLoader, setRemoveBtnLoader] = useState(false)
    const [emptyTableMsg, setEmptyTableMsg] = useState()

    useEffect(()=>{
        if(store.accountManagement['docsModalData'] !== undefined){
            getAllDocs()
        }
    },[store.accountManagement['docsModalData']])

    const options = [
        { key: 'model', text: 'Model', value: 'MODEL' },
        { key: 'method', text: 'Method', value: 'METHOD' },
        { key: 'queryParameter', text: 'Query Parameter', value: 'QUERY_PARAMETER' },
        { key: 'requestBody', text: 'Request Body', value: 'REQUEST_BODY' },
    ]
    
    const methodOption = [
        {key: 'get', text: 'GET', value: 'GET'},
        {key: 'post', text: 'POST', value: 'POST'},
        {key: 'put', text: 'PUT', value: 'PUT'},
        {key: 'delete', text: 'DELETE', value: 'DELETE'},
        {key: 'patch', text: 'PATCH', value: 'PATCH'},
        {key: 'head', text: 'HEAD', value: 'HEAD'},
        {key: 'options', text: 'OPTIONS', value: 'OPTIONS'}
    ]

    const getDocIdForRemove = (e) => {
        if(e.target.checked == true){
            if(store.accountManagement['docsModalData'].type == 'addDocs'){
                setRemoveDocsId([...removeDocsId, e.target.getAttribute('data-key')])
            }
            else if(store.accountManagement['docsModalData'].type == 'addGenericDocs'){
                setRemoveDocsId([...removeDocsId, tableData[e.target.getAttribute('data-key')]])
            }
        }
        else{
            if(store.accountManagement['docsModalData'].type == 'addDocs'){
                removeDocsId.splice(removeDocsId.indexOf(e.target.getAttribute('data-key')), 1)
                setRemoveDocsId([...removeDocsId])
            }
            else if(store.accountManagement['docsModalData'].type == 'addGenericDocs'){
                removeDocsId.splice(removeDocsId.indexOf(tableData[e.target.getAttribute('data-key')]), 1)
                setRemoveDocsId([...removeDocsId])
            }
        }
    }

    const removeDocsHandler = async () => {
        let dataset
        if(store.accountManagement['docsModalData'].type == 'addDocs'){
            dataset = {
                type: 'removeDocs', api_ids: removeDocsId,
                acc_name: store.accountManagement['docsModalData'].acc_name,
                api_id: store.accountManagement['docsModalData'].api_id,
                stage: store.accountManagement['docsModalData'].stage,
            }
        }
        else if(store.accountManagement['docsModalData'].type == 'addGenericDocs'){
            dataset = {
                type: 'removeGenericDocs', items: removeDocsId,
                folderName: store.accountManagement['openedFoldername'],
                fileName: store.accountManagement['docsModalData'].fileName
            }
        }
        
        setRemoveBtnLoader(true)
        await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, dataset, {}))
        .then(
            (res)=>{
                getAllDocs()
                setRemoveDocsId([])
                document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
            }, 
            (err)=>{console.log(err.data)}
        )
        setRemoveBtnLoader(false)
    }

    const docTypeHandler = (e, data) => {
        data = data.value
        if(data){
            setCollectedInputs({...collectedInputs, docType: data})

            if(data === 'MODEL'){
                setInputControl({method: false, path: false, name: true, description: true})
            }else if(data === 'METHOD'){
                setInputControl({method: true, path: true, name: false, description: true})
            }else if(data === 'QUERY_PARAMETER'){
                setInputControl({method: true, path: true, name: true, description: true})
            }else if(data === 'REQUEST_BODY'){
                setInputControl({method: true, path: true, name: false, description: true})
            }
        }else{
            setInputControl({})
            setCollectedInputs({})
        }
    }

    const resetBtnHandler = () => {
        setInputControl({})
        setCollectedInputs({})
    }

    const saveBtnHandler = async () => {
        if(!collectedInputs.docType){
            alert('Documentation type required')
        }else if(collectedInputs.docType == 'MODEL' && (!collectedInputs.name || !collectedInputs.description)){
            alert('Name and Description should not be empty')
        }else if(collectedInputs.docType == 'METHOD' && (!collectedInputs.method || !collectedInputs.path || !collectedInputs.description)){
            alert('Method, Path and Description should not be empty')
        }else if(collectedInputs.docType == 'QUERY_PARAMETER' && (!collectedInputs.method || !collectedInputs.path || !collectedInputs.name || !collectedInputs.description)){
            alert('Method, Path, Name and Description should not be empty')
        }else if(collectedInputs.docType == 'REQUEST_BODY' && (!collectedInputs.method || !collectedInputs.path || !collectedInputs.description)){
            alert('Method, Path and Description should not be empty')
        }else{
            if(store.accountManagement['docsModalData'].type == 'addDocs'){
                collectedInputs['type'] = 'create_docs'
                collectedInputs['acc_name'] = store.accountManagement['docsModalData'].acc_name
                collectedInputs['api_id'] = store.accountManagement['docsModalData'].api_id
                collectedInputs['stage'] = store.accountManagement['docsModalData'].stage
            }else if(store.accountManagement['docsModalData'].type == 'addGenericDocs'){
                collectedInputs['type'] = 'create_generic_docs'
                collectedInputs['folderName'] = store.accountManagement['openedFoldername']
                collectedInputs['fileName'] = store.accountManagement['docsModalData'].fileName
            }
    
            setSaveBtnLoader(true)
            await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, collectedInputs, {}))
            .then(
                (res)=>{
                    alert(res.data)
                    getAllDocs()
                }, 
                (err)=>{alert(err.data)}
            )
            setSaveBtnLoader(false)
        }
    }

    const getAllDocs = async () => {
        let dataset
        
        if(store.accountManagement['docsModalData'].type == 'addDocs'){
            dataset = {
                type: 'readAlldocs',
                acc_name: store.accountManagement['docsModalData']['acc_name'],
                api_id: store.accountManagement['docsModalData']['api_id'],
                stage: store.accountManagement['docsModalData']['stage']
            }
        }else if(store.accountManagement['docsModalData'].type == 'addGenericDocs'){
            dataset = {
                type: 'readAllGenericDocs',
                folderName: store.accountManagement['openedFoldername'],
                fileName: store.accountManagement['docsModalData'].fileName
            }
        }
        await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, dataset, {}))
        .then(
            (res)=>{
                setTableData(res.data)
                setEmptyTableMsg(res.data.length == 0 ? 'You can view the generated documents here' : null)
            }, 
            (err)=>{alert(err.data)}
        )
    }

    return(
        <div>
            <div>
                <Grid doubling container columns={3}>
                    <Grid.Column>
                        <div className='input-card align-temp'>
                            <label>Documentation Type*</label>
                            <Dropdown 
                                placeholder='Select Documentation Type' id='type' clearable fluid selection 
                                options={options} onChange={docTypeHandler} value={collectedInputs.docType || ''}
                            />
                        </div>
                    </Grid.Column>
                    
                    {inputControl.method == true ?
                        <Grid.Column>
                            <div className='input-card align-temp'>
                                <label>Select Method*</label>
                                <Dropdown 
                                    placeholder='Select Method' id='method' clearable fluid selection options={methodOption} 
                                    onChange={(e, data)=>setCollectedInputs({...collectedInputs, method: data.value})}
                                />
                            </div>
                        </Grid.Column>
                        :
                        null
                    }

                    {inputControl.path == true ?
                        <Grid.Column>
                            <div className='input-card align-temp'>
                                <label>Path*</label>
                                <input type='text' id='path' onChange={(e)=>setCollectedInputs({...collectedInputs, path: e.target.value})}/>
                            </div>
                        </Grid.Column>
                        :
                        null
                    }

                    {inputControl.name == true ?
                        <Grid.Column>
                            <div className='input-card align-temp'>
                                <label>Name*</label>
                                <input type='text' id='name' onChange={(e)=>setCollectedInputs({...collectedInputs, name: e.target.value})}/>
                            </div>
                        </Grid.Column>
                        :
                        null
                    }

                    {inputControl.description == true ?
                        <Grid.Column>
                            <div className='input-card align-temp'>
                                <label>Description*</label>
                                <input type='text' id='description' onChange={(e)=>setCollectedInputs({...collectedInputs, description: e.target.value})}/>
                            </div>
                        </Grid.Column>
                        :
                        null
                    }
                </Grid>
            </div>

            <div className='docs-modal-action'>
                <div>
                    <Button primary size='mini' loading={saveBtnLoader} onClick={saveBtnHandler}>
                        <Icon name='save'/>Save
                    </Button>&nbsp;
                    <Button basic size='mini' onClick={resetBtnHandler}><Icon name='remove'/>Reset</Button>
                </div>
                <ModalComponent 
                    content={PublishContent} type='Publish' header='Publish' 
                    triggerName='Publish' triggerIcon='send'
                    disableTrigger={store.accountManagement['openedFoldername'] ? true : false}
                />
            </div>

            {tableData.length > 0 ?
                <div className='docs-table'>
                    <Button loading={removeBtnLoader} content='Standard' 
                            basic size='mini' onClick={removeDocsHandler} 
                            style={{boxShadow: 'none', margin:'5px 0'}}
                            disabled={removeDocsId.length>0 ? false : true}
                    >
                        <Icon name='trash'/> Remove
                    </Button>

                    <Table selectable style={{margin: '0'}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Method</Table.HeaderCell>
                                <Table.HeaderCell>Path</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableData.map((obj, i) => (
                                <Table.Row key={i}>
                                    <Table.Cell style={{display: 'flex', alighItems:'center'}}>
                                        <input type='checkbox' data-key={obj.id || i} onChange={(e)=> getDocIdForRemove(e)} />&nbsp;
                                        <p>{obj.location.type}</p>
                                    </Table.Cell>
                                    <Table.Cell>{obj.location.method || '-'}</Table.Cell>
                                    <Table.Cell>{obj.location.path || '-'}</Table.Cell>
                                    <Table.Cell>{obj.location.name || '-'}</Table.Cell>
                                    <Table.Cell>{obj.properties ? JSON.parse(obj.properties).description : '-'}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            : 
                <p style={{textAlign: 'center', color: '#a0a0a0', marginTop: '20px'}}>{emptyTableMsg ? emptyTableMsg : 'Loading...'}</p>
            }
        </div>
    )
}
