/* eslint-disable */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from "react-router-dom";
import RootLayout from './layouts/RootLayout'
import _ from 'lodash'
import queryString from 'query-string'

// content-fragments.
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages/components
import Home from 'pages/Home'
import GettingStarted from 'pages/GettingStarted'
import AccountsManagement from 'pages/AccountsManagement'
import AWSAccountsList from 'components/API/AWSAccountsList';
import AWSAPIView from 'components/API/AWSAPIView';

import { isAdmin, isRegistered, init, login, logout, getLoginRedirectUrl, isAuthenticated } from 'services/self'
import { cognitoDomain, cognitoClientId } from './services/api'

import './index.css'
import Admin from 'pages/Admin';
import CreateGroupMapping from 'components/CreateGroupMapping';
import UpdateGroupMapping from 'components/UpdateGroupMapping';

loadFragments()

function getCognitoUrl() {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/oauth2/authorize?client_id=${cognitoClientId}&response_type=token&scope=openid&redirect_uri=${redirectUri}`
}

const CognitoCallbackHandler = () => {
  const { action } = queryString.parse(window.location.search)
  if (action === 'login') {
    login()
  } else if (action === 'logout') {
    logout()
  }
  return <Navigate to='/' />
}

const AdminAccessRoute = ({ComponentToPass}) => {
  if(isAdmin()){
    return <ComponentToPass/>
  }else{
    return <Navigate to='/' />
  }
}

const LoginRequiredRoute = ({ComponentToPass}) => {
  if(isAuthenticated() && isRegistered()){
    return <ComponentToPass/>
  }else if(isAuthenticated() && !isRegistered()){
    alert('Please contact the administrator for access.')
    return <Navigate to='/' />
  }else{
    window.location.href = getCognitoUrl()
    return <Navigate to='/' />
  }
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout/>}>
      <Route index element={<Home/>}/>
      <Route path='/getting-started' element={<GettingStarted/>} />
      <Route
        exact
        path='/index.html'
        element={<CognitoCallbackHandler/>}
      />
      <Route path="dashboard" element={<LoginRequiredRoute ComponentToPass={AWSAccountsList}/>}/>
      <Route path="api/docs" element={<LoginRequiredRoute ComponentToPass={AWSAPIView}/>}/>
      <Route path="admin" element={<AdminAccessRoute ComponentToPass={Admin}/>}>
        <Route index element={<AccountsManagement/>}/>
        <Route path="create-mapping" element={<CreateGroupMapping/>}/>
        <Route path="update-mapping" element={<UpdateGroupMapping/>}/>
      </Route>
      <Route path="login" render={() => { login(); return <Navigate to='/' /> }} />
      <Route path="logout" render={() => { logout(); return <Navigate to='/' /> }} />
      <Route path="*" element={<>Page Not Found.</>}/> 
    </Route>
  )
)

const App = () => {
  useEffect(()=>{
    init()
  }, [])

  return(
    <RouterProvider router={router} />
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
