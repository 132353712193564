/* eslint-disable */
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import './SearchBoxComponent.css'

export default function SearchBoxComponent(props){
    useEffect(()=>{}, [])
    return(
        <div className='search-box'>
            <Icon name='search' style={{padding: '0 10px'}}/>
            <input 
                type='text' 
                placeholder='Search' 
                style={{width: props.width, backgroundColor: props.bg, color: props.txtClr}}
                onChange={(e)=>props.searchEvent(e.target.value)}
            />
        </div>
    )
}