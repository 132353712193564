import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Dropdown, Header, Icon } from 'semantic-ui-react'
import { apiGatewayClientWithCredentials } from 'services/api'


const dropdownStyle ={
    margin: "1rem 0",
}

function CreateGroupMapping() {
    const [selectedGroup, setSelectedGroup] = useState()
    const [groupListDropdownOptions, setGroupListDropdownOptions] = useState([])
    
    const [selectedAccountName, setSelectedAccountName] = useState()
    const [selectedAccountId, setSelectedAccountId] = useState()
    const [accountListDropdownOptions, setAccountListDropdownOptions] = useState([])
    
    const [selectedAPIs, setSelectedAPIs] = useState([])
    const [apiStore, setApiStore] = useState([])
    const [apiListDropdownOptions, setApiListDropdownOptions] = useState([])
    
    const [btnLoad, setBtnLoad] = useState(false)

    const renderLabel = (label) => ({
        color: 'grey',
        content: label.text,
    })

    const resetBtnHandler = () => {
        setSelectedGroup('')
        setSelectedAccountId('')
        setSelectedAPIs([])
        setApiStore([])
        setSelectedAccountName(null)
    }

    const fetch_mapping_data = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                let response = res.data
                setGroupListDropdownOptions(
                    response.groups.map((group)=>{return { key: group, text: group, value: group }})
                )
                setAccountListDropdownOptions(
                    response.aws_acc.map((acc) => {return {key: acc.account, text: acc.acc_name, value: acc.account} })
                )
            },
            err=>{
                console.log(err)
            }
        )
    }

    const fetch_acc_apis = async (params, path) => {
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                let response = res.data
                setApiStore(response.api_list)
                setApiListDropdownOptions(
                    response.api_list.map((obj)=>{
                        return { key: obj.id, text: obj.title, value: obj.id, content: (<Header as="h4" content={obj.title} subheader={obj.id} />) }
                    })
                )
            },
            err=>{
                console.log(err)
            }
        )
    }

    const save_mapping_data = async (params, path) => {
        setBtnLoad(true)
        await apiGatewayClientWithCredentials().then(app => app.post(path, {}, {...params}, {}))
        .then(
            res => {
                let response = res.data
                if(response === 'created'){
                    alert('Mapping successfully created.')
                }
                setBtnLoad(false)
            },
            err=>{
                if(err.data === 'exist'){
                    alert("This group and AWS account mapping already exists. Use the 'Update Group Mapping' option to update or delete this mapping.")
                }
                setBtnLoad(false)
            }
        )
    }

    const accountSelectHandler = (value) => {
        setSelectedAPIs([])
        setApiListDropdownOptions([])
        if(value){
            const selectedAccount = accountListDropdownOptions.find(acc => acc.key === value);
            if(selectedAccount) {setSelectedAccountName(selectedAccount.text)}
            setSelectedAccountId(value)
            fetch_acc_apis({type: 'getAccAPIList', account: value}, '/accounts')
        }else{
            setApiStore([])
            setSelectedAccountId(null)
            setSelectedAccountName(null)
        }
    }

    const createBtnHandler = () => {
        let api_meta = apiStore.filter((obj) => { return selectedAPIs.includes(obj.id) })
        save_mapping_data({type: 'create_mapping', flag: 'create', selectedGroup, selectedAccountId, selectedAccountName, apiMeta: api_meta}, '/mapping')
    }

    useEffect(()=>{
        fetch_mapping_data({type: 'fetch_data'}, '/mapping')
    }, [])

    return (
        <div style={{width: '100%', maxWidth: "400px"}}>
            {groupListDropdownOptions && 
                <Dropdown
                    style={dropdownStyle}
                    options={groupListDropdownOptions}
                    placeholder='Choose Group'
                    search
                    selection
                    fluid
                    clearable
                    value={selectedGroup}
                    onChange={(e, {value}) => {setSelectedGroup(value)}}
                />
            }
            {selectedGroup && 
                <Dropdown
                    style={dropdownStyle}
                    options={accountListDropdownOptions}
                    placeholder='Choose Account'
                    search
                    selection
                    fluid
                    clearable
                    value={selectedAccountId}
                    onChange={(e, {value}) => { accountSelectHandler(value) }}
                />
            }
            {(selectedGroup && selectedAccountId) &&
                <Dropdown
                    style={dropdownStyle}
                    options={apiListDropdownOptions}
                    placeholder='Choose API'
                    search
                    selection
                    fluid
                    multiple
                    clearable
                    value={selectedAPIs}
                    renderLabel={renderLabel}
                    onChange={(e, {value}) => {setSelectedAPIs(value)}}
                />
            }
            {(selectedGroup && selectedAccountId && selectedAPIs.length>0) &&
                <>
                    <Button primary onClick={createBtnHandler} loading={btnLoad} disabled={btnLoad}><Icon name='save' /> Create</Button> &nbsp;&nbsp;
                    <Button onClick={resetBtnHandler}><Icon name='undo' /> Reset</Button>
                </>
            }
        </div>
    )
}

export default CreateGroupMapping