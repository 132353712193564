/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

// state
import { store } from 'services/state.js'

// client credentials
import { apiGatewayClientWithCredentials } from 'services/api'

import './accountManagement.css'

export default function ModalComponent(props){
    // modal
    const [open, setOpen] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [publishLoader, setPublishLoader] = useState(false)
    
    const hasWhiteSpace = (s) => {
        return s.indexOf(' ') >= 0;
    }

    useEffect(()=>{

    },[])
    
    const api_callback = async (params) => {
        await apiGatewayClientWithCredentials().then(app => app.post('/accounts', {}, {...params}, {}))
        .then(res => {
            window.location.reload()
        },err=>{
            window.location.reload()
        })
        setBtnLoader(false)
    }

    
    const btnClick = () => {
        if(props.type == 'addAccount'){
            let format = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
            let acc_data = {type:props.type, ...store.accountManagement}

            if(acc_data.accountName && acc_data.roleARN){
                if(hasWhiteSpace(acc_data.accountName)){
                    alert("Account name can't contains white space")
                }
                else if(format.test(acc_data.accountName)){
                    alert("Account name should not contain special characters")
                }
                else{
                    setBtnLoader(true)
                    api_callback(acc_data)
                }
            }else{
                alert('Enter all required fields')
            }
        }
    }

    const deleteHandler = () => {
        if(props.type == 'deleteAccount'){
            let acc_data = {type: props.type, account: props.acc_id, acc_name: props.acc_name}
            setBtnLoader(true)
            api_callback(acc_data)
        }else{
            alert('Invalid attempt')
        }
    }

    const closeHandler = () => {
        setOpen(false)
        if(props.type !== 'Publish'){
            store.accountManagement['docsModalData'] = undefined
        }
    }

    const docsHandler = () => {
        if(props.type == 'addDocs'){
            store.accountManagement['openedFoldername'] = undefined
        }
        store.accountManagement['docsModalData'] = props
    }

    const setFoldername = () => store.accountManagement['openedFoldername'] = props.triggerName

    const publishVersion = async () => {
        setPublishLoader(true)
        let data = {
            type: 'publish',
            acc_name: store.accountManagement['docsModalData'].acc_name,
            api_id: store.accountManagement['docsModalData'].api_id,
            stage: store.accountManagement['docsModalData'].stage,
            publishVersion: store.accountManagement['publishVersion']
        }

        if(data.publishVersion){
            await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, data, {}))
            .then(
                res => {
                    alert(res.data)
                    setOpen(false)
                    store.accountManagement['publishVersion']=undefined
                },
                err=>{
                    alert(err.data)
                }
            )
        }else{
            alert('Publish version is required')
        }
        setPublishLoader(false)
    }

    const addFolder = async () => {
        let folderName = store.accountManagement['createFolder']
        var format = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
        
        if(!folderName){
            alert("Folder name required")
        }
        else if(hasWhiteSpace(folderName)){
            alert("White space not allowed")
        }else if(format.test(folderName)){
            alert("Special characters not allowed")
        }
        else if(folderName.length < 3 || folderName.length > 63){
            alert('Folder name should be min 3 and max 63 letters')
        }
        else{
            setBtnLoader(true)
            await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, {folderName: folderName, type:'create_folder'}, {}))
            .then(
                res => {
                    setOpen(false)
                    window.location.reload(false)
                },
                err=>{
                    alert(err.data)
                }
            )
            setBtnLoader(false)
        }
    }

    const deleteFolderHandler = async () => {
        setBtnLoader(true)
        await apiGatewayClientWithCredentials().then(app => app.post('/apidocs', {}, {folderName: props.folderName, type:'folderDelete'}, {}))
        .then(
            res => {
                setOpen(false)
                window.location.reload(false)
            },
            err=>{
                alert(err.data)
            }
        )
        setBtnLoader(false)
    }

    return (
        <Modal
            open={open}
            trigger={
                props.type == 'addAccount' || props.type == 'addFolder' ? 
                    <Button primary size='mini'><Icon name={props.triggerIcon} /> {props.triggerName}</Button>
                : props.type == 'deleteAccount' ?
                    <Button size='mini' basic color='red' style={{float: 'right'}}>
                        <Icon name={props.triggerIcon}/> {props.triggerName}
                    </Button>
                : props.type == 'deleteFolder' ?
                    <Button size='mini' basic color='red'>
                        <Icon name={props.triggerIcon}/> {props.triggerName}
                    </Button>
                : props.type == 'addDocs' ?
                    <Icon name='pencil' className='docs' onClick={docsHandler}/>
                : props.type == 'addGenericDocs' ?
                    <Icon name='pencil' className='docs' onClick={docsHandler}/>
                : props.type == 'Publish' ?
                    <Button size='mini' disabled={props.disableTrigger || false} color='green'><Icon name='send'/>Publish</Button>
                : props.type == 'Folder'?
                    <div className='folder-box' onClick={setFoldername}><Icon name={props.triggerIcon} color='grey' size='huge'/>
                        <p style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} title={props.triggerName}>
                            {props.triggerName}
                        </p>
                    </div>
                : null
            }
            onClose={closeHandler}
            onOpen={() => setOpen(true)}
            dimmer='blurring'
            size={props.size}
        >
            {props.header ? <Header>{props.header}</Header> : null }
            <Modal.Content>
                {props.content()}
            </Modal.Content>
            <Modal.Actions>
                <Button basic size='mini' onClick={() => setOpen(false)}><Icon name='remove' /> Cancel</Button>
                {
                props.type == 'addAccount' ?
                    <Button loading={btnLoader} size='mini' primary onClick={btnClick}><Icon name='checkmark' /> Send</Button>
                : props.type == 'deleteAccount' ?
                    <Button loading={btnLoader} size='mini' secondary onClick={deleteHandler}><Icon name='remove' /> Delete</Button>
                : props.type == 'Publish' ?
                    <Button loading={publishLoader} size='mini' primary onClick={publishVersion}><Icon name='checkmark'/> Send</Button>
                : props.type == 'addFolder' ?
                    <Button loading={btnLoader} size='mini' primary onClick={addFolder}><Icon name='checkmark' /> Send</Button>
                : props.type == 'deleteFolder' ?
                    <Button loading={btnLoader} size='mini' secondary onClick={deleteFolderHandler}><Icon name='remove' /> Delete</Button>
                : null
                }
            </Modal.Actions>
        </Modal>
    )
}
